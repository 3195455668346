<template>
    <div class="pageContainer">
        <a-row class="CheckAdvertisingBox" :gutter="8">
            <a-spin :spinning="payLoading">
                <a-col class="CheckAdvertisingBox-left" :span="6">
                    <div class="CheckAdvertisingBox-left-box">
                        <div class="title">广告检测</div>
                        <a-divider></a-divider>
                        <div class="detectTypeBox">
                            <div class="subTitle flex justify-between">
                                <span>选择检测方式</span>
                                <a @click="previewVideo">使用教程</a>
                            </div>
                            <div class="prompt">
                                <a-alert
                                    :message="currentDetectTyType == 1 ? '智能检测由机器完成，仅供参考，3分钟内出结果。' : '人工/专家检测准确率可达到95%以上，30分钟内出结果。'"
                                    type="info"
                                    show-icon />
                            </div>
                            <div class="detectTyBtnGroup btnGroup">
                                <a-button @click="selectedDetectyType(item.type)" :type="currentDetectTyType == item.type ? 'primary' : ''" v-for="(item, index) in detectTyBtnList" :key="index">
                                    {{ item.name }}
                                </a-button>
                            </div>
                            <a-row class="expertNumber flex justify-content align-center" v-if="currentDetectTyType == 3">
                                <a-form-model ref="reviewUserForm" :model="reviewUserCodeForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
                                    <a-form-model-item label="专家编号" prop="reviewUserCode">
                                        <a-input placeholder="请输入专家编号" v-model="reviewUserCodeForm.reviewUserCode" />
                                    </a-form-model-item>
                                </a-form-model>
                            </a-row>
                        </div>
                        <a-divider></a-divider>
                        <div class="materialSelectedBox">
                            <div class="subTitle">待检测的素材内容</div>
                            <div class="materialBtnGroup btnGroup">
                                <a-button @click="selectedMaterialType(item.type)" :type="item.check ? 'primary' : ''" v-for="(item, index) in materialBtnList" :key="index">{{ item.name }}</a-button>
                            </div>
                            <div class="discountBox" v-if="currentDetectTyType == 2">
                                <a-checkbox :disabled="this.couponUseType != 0 || this.discount != true" @change="onChange">优惠券</a-checkbox>
                                <a-select :disabled="this.couponUseType != 0 || this.discount != true" placeholder="请选择优惠内容" v-model="couponType">
                                    <a-select-option :value="1">图片</a-select-option>
                                    <a-select-option :value="2">视频</a-select-option>
                                    <a-select-option :value="3">音频</a-select-option>
                                    <a-select-option :value="4">文本</a-select-option>
                                </a-select>
                            </div>
                        </div>
                        <a-divider></a-divider>
                        <div class="industrySelectedBox">
                            选择广告投放行业：
                            <a-select placeholder="请选择行业" show-search v-model="industry" @change="handleChange" :filter-option="filterOption">
                                <a-select-option v-for="item in industryOptions" :key="item.flowId" :value="item.industryName">{{ item.industryName }}</a-select-option>
                            </a-select>
                            <div class="prompt">
                                <a-alert message="行业选择会影响检测结果的准确率。" banner />
                            </div>
                        </div>
                        <div class="commonIndustriesBox">
                            <template>
                                <a-button v-for="item in commonIndustries" @click="handleChange(item.industryName)" :type="industry == item.industryName ? 'primary' : ''" :key="item.flowId">
                                    {{ item.industryName }}
                                </a-button>
                            </template>
                        </div>
                        <div class="textareaBox">
                            <a-textarea
                                class="textareaValue"
                                readOnly
                                placeholder="广告常见违法情形汇总（请选择行业）"
                                v-model="textareaValue"
                                :rows="10"
                                :auto-size="{ minRows: textAreaMinRows, maxRows: textAreaMaxRows }" />
                        </div>
                    </div>
                </a-col>
                <a-col class="CheckAdvertisingBox-right" :span="18">
                    <div class="CheckAdvertisingBox-right-box">
                        <div class="uploadContainer" v-for="(item2, index2) in materialBtnList" :key="index2">
                            <div v-if="item2.type == 1 && item2.check" class="imgUploadBox uploadBox">
                                <div class="prompt" v-if="currentDetectTyType === 1">
                                    <a-alert description="图片请上传jpeg、png、bmp、gif格式图片，单个图片大小不可超过4MB，图片个数不超过8个" type="success" />
                                </div>
                                <div class="prompt" v-if="currentDetectTyType != 1">
                                    <a-alert
                                        description="图片请上传jpeg、png、bmp、gif格式图片，单个图片大小不可超过4MB，图片个数不超过8个（如果有资质文件请上传到所对应图片的下方-支持图片、视频、文档、压缩包格式）"
                                        type="success" />
                                </div>
                                <div style="margin-left: 16px">
                                    <a-upload
                                        :action="resourcesHost"
                                        list-type="picture-card"
                                        :before-upload="beforeImgUpload"
                                        :file-list="imgFileList"
                                        @preview="handlePreview"
                                        @change="handleImgChange">
                                        <div v-if="imgFileList.length < 8">
                                            <a-icon type="plus" />
                                            <div class="ant-upload-text">上传图片</div>
                                        </div>
                                    </a-upload>
                                </div>
                                <div class="qualificatBox">
                                    <div v-for="item in imgFileList" :key="item.uid" class="qualificatItme">
                                        <a-upload
                                            v-if="currentDetectTyType != 1"
                                            :action="resourcesHost"
                                            list-type="picture-card"
                                            :before-upload="beforefileUpload"
                                            :file-list="item.qualificationList || []"
                                            @preview="handlePreview"
                                            @change="
                                                ({ file, fileList }) => {
                                                    handlefileChange({ file, fileList }, item.response.data.id);
                                                }
                                            "
                                            maxCount="1">
                                            <div v-if="!item.qualificationList || (item.qualificationList && item.qualificationList.length === 0)">
                                                <a-icon type="plus" />
                                                <div class="qua-upload-text">上传资质文件</div>
                                            </div>
                                        </a-upload>
                                    </div>
                                </div>
                                <a-divider></a-divider>
                                <div
                                    class="priceBox flex justify-between align-center"
                                    v-if="userInfo.userType == 1 || (userInfo.userType == 2 && !freePeriod) || (userInfo.userType == 2 && freePeriod && currentDetectTyType != 1)">
                                    <div>
                                        价格明细：
                                        <span class="price">
                                            ￥{{
                                                currentDetectTyType == 1
                                                    ? imgUnitPrice.intelligence
                                                    : currentDetectTyType == 2
                                                    ? imgUnitPrice.artificial
                                                    : !customPriceFlag
                                                    ? (Number((imgUnitPrice.artificial * (imgUnitPrice.expert.replace('%', '') / 100)).toFixed(2)) + Number(imgUnitPrice.artificial)).toFixed(2)
                                                    : imgUnitPrice.unitPrice
                                            }}元/张
                                        </span>
                                        X {{ imgFileList.length }} 张， 合计：{{
                                            currentDetectTyType == 1
                                                ? (imgUnitPrice.intelligence * imgFileList.length).toFixed(2)
                                                : currentDetectTyType == 2
                                                ? (imgUnitPrice.artificial * imgFileList.length).toFixed(2)
                                                : !customPriceFlag
                                                ? (
                                                      (Number((imgUnitPrice.artificial * (imgUnitPrice.expert.replace('%', '') / 100)).toFixed(2)) + Number(imgUnitPrice.artificial)).toFixed(2) *
                                                      imgFileList.length
                                                  ).toFixed(2)
                                                : (imgUnitPrice.unitPrice * imgFileList.length).toFixed(2)
                                        }}
                                        元
                                    </div>
                                    <div class="discountSize" v-if="currentDetectTyType == 2 && discount == true">
                                        使用优惠券将减免
                                        <span slot="discountForm">{{ discountForm.pictureCount }}张图片</span>
                                    </div>
                                    <div class="discountSize" v-if="currentDetectTyType == 2 && discount == false">您未拥有优惠券或优惠券已用完，建议您参与活动获取优惠券，享受优惠减免</div>
                                    <a-popconfirm title="取消检测" @confirm="selectedMaterialType(item2.type)">
                                        <div class="cancelBtn">
                                            <a-button type="primary" size="small">取消检测</a-button>
                                        </div>
                                    </a-popconfirm>
                                </div>
                                <a-divider></a-divider>
                            </div>
                            <div v-if="item2.type == 2 && item2.check" class="videoUploadBox uploadBox">
                                <div class="prompt" v-if="currentDetectTyType === 1">
                                    <a-alert description="视频请上传mov、rmvb、mp4、mkv、rm格式，单视频大小不超过1G（1024MB），视频个数不超过8个" type="success" />
                                </div>
                                <div class="prompt" v-if="currentDetectTyType != 1">
                                    <a-alert
                                        description="视频请上传mov、rmvb、mp4、mkv、rm格式，单视频大小不超过1G（1024MB），视频个数不超过8个（如果有资质文件请上传到所对应视频的下方-支持图片、视频、文档、压缩包格式）"
                                        type="success" />
                                </div>
                                <div style="margin-left: 16px">
                                    <a-upload
                                        :action="resourcesHost"
                                        list-type="picture-card"
                                        :file-list="videoFileList"
                                        :before-upload="beforeVideoUpload"
                                        @preview="handlePreview"
                                        @change="handleVideoChange">
                                        <div v-if="videoFileList.length < 8">
                                            <a-icon type="plus" />
                                            <div class="ant-upload-text">上传视频</div>
                                        </div>
                                    </a-upload>
                                </div>
                                <div class="qualificatBox">
                                    <div v-for="item in videoFileList" :key="item.uid" class="qualificatItme">
                                        <a-upload
                                            :action="resourcesHost"
                                            list-type="picture-card"
                                            :file-list="item.qualificationList || []"
                                            :before-upload="beforefileUpload"
                                            @preview="handlePreview"
                                            @change="
                                                ({ file, fileList }) => {
                                                    handlefileChange({ file, fileList }, item.response.data.id);
                                                }
                                            ">
                                            <div v-if="!item.qualificationList || (item.qualificationList && item.qualificationList.length === 0)">
                                                <a-icon type="plus" />
                                                <div class="ant-upload-text">上传资质文件</div>
                                            </div>
                                        </a-upload>
                                    </div>
                                </div>
                                <a-divider></a-divider>
                                <div
                                    class="priceBox flex justify-between align-center"
                                    v-if="userInfo.userType == 1 || (userInfo.userType == 2 && !freePeriod) || (userInfo.userType == 2 && freePeriod && currentDetectTyType != 1)">
                                    <div>
                                        价格明细：
                                        <span class="price">
                                            ￥{{
                                                currentDetectTyType == 1
                                                    ? videoUnitPrice.intelligence
                                                    : currentDetectTyType == 2
                                                    ? videoUnitPrice.artificial
                                                    : !customPriceFlag
                                                    ? (Number((videoUnitPrice.artificial * (videoUnitPrice.expert.replace('%', '') / 100)).toFixed(2)) + Number(videoUnitPrice.artificial)).toFixed(2)
                                                    : videoUnitPrice.unitPrice
                                            }}元/秒
                                        </span>
                                        X {{ totalVideoDuration }} 秒， 合计：{{
                                            currentDetectTyType == 1
                                                ? (videoUnitPrice.intelligence * totalVideoDuration).toFixed(2)
                                                : currentDetectTyType == 2
                                                ? (videoUnitPrice.artificial * totalVideoDuration).toFixed(2)
                                                : !customPriceFlag
                                                ? (
                                                      (Number((videoUnitPrice.artificial * (videoUnitPrice.expert.replace('%', '') / 100)).toFixed(2)) + Number(videoUnitPrice.artificial)).toFixed(2) *
                                                      totalVideoDuration
                                                  ).toFixed(2)
                                                : (videoUnitPrice.unitPrice * totalVideoDuration).toFixed(2)
                                        }}
                                        元
                                    </div>
                                    <div class="discountSize" v-if="currentDetectTyType == 2 && discount == true">
                                        使用优惠券将减免
                                        <span slot="discountForm">{{ discountForm.videoCount }}秒视频(含{{ discountForm.videoCount }}秒视频)</span>
                                    </div>
                                    <div class="discountSize" v-if="currentDetectTyType == 2 && discount == false">您未拥有优惠券或优惠券已用完，建议您参与活动获取优惠券，享受优惠减免</div>
                                    <a-popconfirm title="取消检测" @confirm="selectedMaterialType(item2.type)">
                                        <div class="cancelBtn">
                                            <a-button type="primary" size="small">取消检测</a-button>
                                        </div>
                                    </a-popconfirm>
                                </div>
                                <a-divider></a-divider>
                            </div>
                            <div v-if="item2.type == 3 && item2.check" class="audeoUploadBox uploadBox">
                                <div class="prompt" v-if="currentDetectTyType === 1">
                                    <a-alert message="温馨提示" description="音频请上传mp3、wav格式，单音频大小不超过20MB，音频个数不超过8个" type="success" />
                                </div>
                                <div class="prompt" v-if="currentDetectTyType != 1">
                                    <a-alert
                                        message="温馨提示"
                                        description="音频请上传mp3、wav格式，单音频大小不超过20MB，音频个数不超过8个（如果有资质文件请上传到所对应音频的下方-支持图片、视频、文档、压缩包格式）"
                                        type="success" />
                                </div>
                                <div style="margin-left: 16px">
                                    <a-upload
                                        :action="resourcesHost"
                                        list-type="picture-card"
                                        :file-list="audioFileList"
                                        :before-upload="beforeAudioUpload"
                                        @preview="handlePreview"
                                        @change="handleAudioChange">
                                        <div v-if="audioFileList.length < 8">
                                            <a-icon type="plus" />
                                            <div class="ant-upload-text">上传音频</div>
                                        </div>
                                    </a-upload>
                                </div>
                                <div class="qualificatBox">
                                    <div v-for="item in audioFileList" :key="item.uid" class="qualificatItme">
                                        <a-upload
                                            :action="resourcesHost"
                                            list-type="picture-card"
                                            :file-list="item.qualificationList || []"
                                            :before-upload="beforefileUpload"
                                            @preview="handlePreview"
                                            @change="
                                                ({ file, fileList }) => {
                                                    handlefileChange({ file, fileList }, item.response.data.id);
                                                }
                                            ">
                                            <div v-if="!item.qualificationList || (item.qualificationList && item.qualificationList.length === 0)">
                                                <a-icon type="plus" />
                                                <div class="ant-upload-text">上传资质文件</div>
                                            </div>
                                        </a-upload>
                                    </div>
                                </div>

                                <a-divider></a-divider>
                                <div
                                    class="priceBox flex justify-between align-center"
                                    v-if="userInfo.userType == 1 || (userInfo.userType == 2 && !freePeriod) || (userInfo.userType == 2 && freePeriod && currentDetectTyType != 1)">
                                    <div>
                                        价格明细：
                                        <span class="price">
                                            ￥{{
                                                currentDetectTyType == 1
                                                    ? audioUnitPrice.intelligence
                                                    : currentDetectTyType == 2
                                                    ? audioUnitPrice.artificial
                                                    : !customPriceFlag
                                                    ? (Number((audioUnitPrice.artificial * (audioUnitPrice.expert.replace('%', '') / 100)).toFixed(2)) + Number(audioUnitPrice.artificial)).toFixed(2)
                                                    : audioUnitPrice.unitPrice
                                            }}元/秒
                                        </span>
                                        X {{ totalAudioDuration }} 秒， 合计：{{
                                            currentDetectTyType == 1
                                                ? (audioUnitPrice.intelligence * totalAudioDuration).toFixed(2)
                                                : currentDetectTyType == 2
                                                ? (audioUnitPrice.artificial * totalAudioDuration).toFixed(2)
                                                : !customPriceFlag
                                                ? (
                                                      (Number((audioUnitPrice.artificial * (audioUnitPrice.expert.replace('%', '') / 100)).toFixed(2)) + Number(audioUnitPrice.artificial)).toFixed(2) *
                                                      totalAudioDuration
                                                  ).toFixed(2)
                                                : (audioUnitPrice.unitPrice * totalAudioDuration).toFixed(2)
                                        }}
                                        元
                                    </div>
                                    <div class="discountSize" v-if="currentDetectTyType == 2 && discount == true">
                                        使用优惠券将减免
                                        <span slot="discountForm">{{ discountForm.audioCount }}秒音频(含{{ discountForm.audioCount }}秒音频)</span>
                                    </div>
                                    <div class="discountSize" v-if="currentDetectTyType == 2 && discount == false">您未拥有优惠券或优惠券已用完，建议您参与活动获取优惠券，享受优惠减免</div>
                                    <a-popconfirm title="取消检测" @confirm="selectedMaterialType(item2.type)">
                                        <div class="cancelBtn">
                                            <a-button type="primary" size="small">取消检测</a-button>
                                        </div>
                                    </a-popconfirm>
                                </div>
                                <a-divider></a-divider>
                            </div>
                            <div v-if="item2.type == 4 && item2.check" class="textareaBox uploadBox">
                                <div class="prompt">
                                    <a-alert description="文本字数不超过3000字（包含符号）" type="success" />
                                </div>
                                <div class="textareaBox">
                                    <a-textarea class="textarea" v-model="content" placeholder="输入需要检测的文本" :auto-size="{ minRows: 5, maxRows: 8 }"></a-textarea>
                                </div>
                                <a-divider></a-divider>
                                <div
                                    class="priceBox flex justify-between align-center"
                                    v-if="userInfo.userType == 1 || (userInfo.userType == 2 && !freePeriod) || (userInfo.userType == 2 && freePeriod && currentDetectTyType != 1)">
                                    <div>
                                        价格明细：
                                        <span class="price">
                                            ￥ {{ !customPriceFlag ? getTextPriceDefault() : textUnitPrice.minFontPrice }}/140字，超出后按{{
                                                !customPriceFlag ? getTextPriceMax() : textUnitPrice.maxFontPrice
                                            }}元/字计算
                                        </span>
                                        <!-- // &lt; 等同于小于 -->
                                        X {{ content.length }} 字，合计：{{
                                            content.length < 141
                                                ? !customPriceFlag
                                                    ? getTextPriceDefault()
                                                    : textUnitPrice.minFontPrice
                                                : !customPriceFlag
                                                ? getTextPriceTotal()
                                                : (textUnitPrice.minFontPrice + textUnitPrice.maxFontPrice * (content.length - 140)).toFixed(2)
                                        }}元
                                    </div>
                                    <div class="discountSize" v-if="currentDetectTyType == 2 && discount == true">
                                        使用优惠券将减免
                                        <span slot="discountForm">{{ discountForm.textCount }}字文本(含{{ discountForm.textCount }}字)</span>
                                    </div>
                                    <div class="discountSize" v-if="currentDetectTyType == 2 && discount == false">您未拥有优惠券或优惠券已用完，建议您参与活动获取优惠券，享受优惠减免</div>
                                    <a-popconfirm title="取消检测" @confirm="selectedMaterialType(item2.type)">
                                        <div class="cancelBtn">
                                            <a-button type="primary" size="small">取消检测</a-button>
                                        </div>
                                    </a-popconfirm>
                                </div>
                                <a-divider></a-divider>
                            </div>
                        </div>
                        <div class="prompt">
                            <a-alert message="如未出具结果系统将原路退回支付费用。" banner />
                        </div>
                        <div class="submitContainer flex justify-end align-center">
                            <div class="submitBox">
                                <font v-if="userInfo.userType == 1 || (userInfo.userType == 2 && !freePeriod) || (userInfo.userType == 2 && freePeriod && currentDetectTyType != 1)">
                                    付款金额：{{ totalPrice > 0 ? totalPrice : 0 }}元， 折扣减免： {{ totalDiscountAmount > 0 ? totalDiscountAmount : 0 }}元， 优惠券减免：
                                    {{ discountprice > 0 ? discountprice : 0 }}元， 实付金额：
                                    <span style="font-size: 24px">{{ totalPayAmount }}</span>
                                    元
                                </font>
                                <a-button type="primary" class="submitBtn" @click="submit">提交检测</a-button>
                            </div>
                        </div>
                    </div>
                </a-col>
            </a-spin>
        </a-row>
        <Vviewer ref="viewer"></Vviewer>
        <MediaViewer :visible="mediaVisible" :mediaSrc="mediaSrc" :mediaType="mediaType" :closeMediaModal="closeMediaModal" />
        <PayModal :payData="payData" :payModalVisible="payModalVisible" :tradeId="tradeId" :closePayModal="closePayModal" />
        <ChangePassWordModal :phone="userInfo.phone" :changePassWordVisible="changePassWordVisible" :closeChangePassWordModal="closeChangePassWordModal" />
    </div>
</template>

<script>
import Vviewer from '../../components/Vviewer';
import MediaViewer from '../../components/MediaViewer.vue';
import PayModal from '../../components/PayModal';
import ChangePassWordModal from './components/ChangePassWordModal.vue';
import IndustryInfo from '../../utils/common/industryViolation.json';

export default {
    components: {
        Vviewer,
        MediaViewer,
        PayModal,
        ChangePassWordModal,
    },
    data() {
        const _this = this;
        let checkReviewUserCode = (rule, value, callback) => {
            if (value && value != '') {
                _this.$http
                    .get('/userbasic/queryReport?userCode=' + value)
                    .then(res => {
                        callback();
                        _this.reviewUserCodeFaild = false;
                        _this.getUserSetting(value);
                    })
                    .catch(err => {
                        _this.reviewUserCodeFaild = true;
                        callback(new Error('专家编号错误，请重新输入'));
                    });
            } else {
                _this.reviewUserCodeFaild = true;
                callback(new Error('请输入专家编号'));
            }
        };
        return {
            labelCol: { xl: { span: 6 } },
            wrapperCol: {
                xl: { span: 18 },
            },
            reviewUserCodeForm: {
                reviewUserCode: undefined,
            },
            currentDetectTyType: 1,
            payLoading: true,
            couponState: '',
            detectTyBtnList: [
                {
                    type: 1,
                    name: '智能检测',
                },
                {
                    type: 2,
                    name: '人工检测',
                },
                {
                    type: 3,
                    name: '专家检测',
                },
            ],
            materialBtnList: [
                {
                    type: 1,
                    name: '添加图片',
                    check: true,
                },
                {
                    type: 2,
                    name: '添加视频',
                    check: false,
                },
                {
                    type: 3,
                    name: '添加音频',
                    check: false,
                },
                {
                    type: 4,
                    name: '添加文本',
                    check: false,
                },
            ],
            ambientHost: process.env.NODE_ENV,
            industryOptions: [],
            textareaValue: '',
            imgFileList: [],
            videoFileList: [],
            audioFileList: [],
            qualificationFile: [],
            FileInfo: [],
            resourcesHost: '',
            mediaSrc: null,
            mediaType: null,
            mediaVisible: false,
            industry: undefined,
            couponType: 1,
            couponUseType: '', //优惠券是否使用
            discount: true, //是否有优惠券
            content: '',
            payData: {},
            payModalVisible: false,
            totalDiscountAmount: 0, // 折扣金额
            totalPayAmount: 0, // 实付金额
            totalPrice: 0, // 总金额
            tradeId: null, // 主订单号
            discountprice: 0, //优惠金额
            imgUnitPrice: {},
            videoUnitPrice: {},
            audioUnitPrice: {},
            textUnitPrice: {},
            totalVideoDuration: 0,
            totalAudioDuration: 0,
            userInfo: {},
            freePeriod: false, // 政府账号免费审读有效期：true：在期限内，false：已过免费期
            customPriceFlag: false, // 输入专家编号后，该专家是否有自定义价格标志：true：有，false：没有
            realTimePrice: {
                imgPrice: {},
                videoPrice: {},
                audioPrice: {},
                textPrice: {},
            },
            rules: {
                reviewUserCode: [{ required: true, validator: checkReviewUserCode, trigger: 'change' }],
            },
            reviewUserCodeFaild: false,
            commonIndustries: [],
            textAreaMinRows: 9,
            textAreaMaxRows: 9,
            changePassWordVisible: false,
            discountForm: {
                videoCount: '',
                audioCount: '',
                pictureCount: '',
                textCount: '',
            },
            imgNum: 0,
            videoNum: 0,
            audioNum: 0,
            dataList: [],
            uploadNum: 0,
        };
    },
    watch: {
        currentDetectTyType(data) {
            if (data == 1 || data == 2) {
                this.reviewUserCodeForm.reviewUserCode = undefined;
                this.reviewUserCodeFaild = false;
                this.customPriceFlag = false;
                this.querySystemsettingPageList();
                this.textAreaMinRows = 9;
                this.textAreaMaxRows = 9;
            } else {
                this.textAreaMinRows = 6;
                this.textAreaMaxRows = 6;
            }
            this.getPrice();
        },
        content(data) {
            if (data.length > 3000) {
                this.$message.warning('字数不能超过3000字！');
                this.content = this.content.substring(0, 3000);
            } else {
                this.getPrice();
            }
        },
        //选择优惠券优惠类型
        couponType(data) {
            if (data) {
                this.getPrice();
            }
        },
    },
    created() {
        this.resourcesHost = process.env.VUE_APP_RESOURCES_API_HOST;
        this.userInfo = this.$store.getters.userInfo;
        if (this.userInfo.expirationTime >= this.$moment().valueOf()) {
            this.freePeriod = true;
        }
        this.querySystemsettingPageList();
        this.industryOptionsList();
        this.getUserCouponId();
        if (this.$route.query.tokenKey) {
            this.selectValueByKey();
        }
        if (this.$route.query.resetPassword == 1) {
            this.showChangePassWordModal();
        }
    },
    methods: {
        setAmount() {
            const _this = this;
            _this.dataList.map(item => {
                if (item.subscript == 1) {
                    let index = _this.dataList.filter(item => {
                        return item.subscript == 1 && item.materialtotalPayAmount * 1000 == 0;
                    });
                    if (item.materialtotalPayAmount * 1000 == 0) {
                        for (let j = 0; j < index.length; j++) {
                            _this.imgFileList[j].payAmount = item.materialtotalPayAmount;
                            _this.imgNum = Math.max(j) + 1;
                        }
                    } else if (item.materialtotalPayAmount * 1000 > 0) {
                        for (let j = _this.imgNum; j < this.imgFileList.length; j++) {
                            _this.imgFileList[j].payAmount = item.materialtotalPayAmount;
                        }
                    }
                    _this.realTimePrice.imgPrice.priceAmount = item.materialPrice;
                    _this.realTimePrice.imgPrice.discountAmount = item.discountAmount;
                    _this.realTimePrice.imgPrice.unitPrice = item.unitPrice;
                } else if (item.subscript == 2) {
                    let index = _this.dataList.filter(item => {
                        return item.subscript == 2 && item.materialtotalPayAmount * 1000 == 0;
                    });
                    if (item.materialtotalPayAmount * 1000 == 0) {
                        for (let j = 0; j < index.length; j++) {
                            _this.videoFileList[j].payAmount = item.materialtotalPayAmount;
                            _this.videoNum = Math.max(j) + 1;
                        }
                    } else if (item.materialtotalPayAmount * 1000 > 0) {
                        for (let j = _this.videoNum; j < _this.videoFileList.length; j++) {
                            this.videoFileList[j].payAmount = item.materialtotalPayAmount;
                        }
                    }
                    _this.realTimePrice.videoPrice.priceAmount = item.materialPrice;
                    _this.realTimePrice.videoPrice.discountAmount = item.discountAmount;
                    _this.realTimePrice.videoPrice.unitPrice = item.unitPrice;
                } else if (item.subscript == 3) {
                    let index = _this.dataList.filter(item => {
                        return item.subscript == 3 && item.materialtotalPayAmount * 1000 == 0;
                    });
                    if (item.materialtotalPayAmount * 1000 == 0) {
                        for (let j = 0; j < index.length; j++) {
                            _this.audioFileList[j].payAmount = item.materialtotalPayAmount;
                            _this.audioNum = Math.max(j) + 1;
                        }
                    } else if (item.materialtotalPayAmount * 1000 > 0) {
                        for (let j = _this.audioNum; j < _this.audioFileList.length; j++) {
                            this.audioFileList[j].payAmount = item.materialtotalPayAmount;
                        }
                    }
                    _this.realTimePrice.audioPrice.priceAmount = item.materialPrice;
                    _this.realTimePrice.audioPrice.discountAmount = item.discountAmount;
                    _this.realTimePrice.audioPrice.unitPrice = item.unitPrice;
                } else if (item.subscript == 4) {
                    _this.realTimePrice.textPrice.priceAmount = item.materialPrice;
                    _this.realTimePrice.textPrice.payAmount = item.materialtotalPayAmount;
                    _this.realTimePrice.textPrice.discountAmount = item.discountAmount;
                    _this.realTimePrice.textPrice.minFontPrice = item.minFontPrice;
                    _this.realTimePrice.textPrice.maxFontPrice = item.maxFontPrice;
                }
            });
        },
        closeChangePassWordModal() {
            this.changePassWordVisible = false;
        },
        showChangePassWordModal() {
            const _this = this;
            setTimeout(() => {
                _this.changePassWordVisible = true;
            }, 1000);
        },
        selectValueByKey() {
            const _this = this;
            let params = {
                sourceMaterialKey: this.$route.query.sourceMaterialKey,
                tokenKey: this.$route.query.tokenKey,
                userKey: this.$route.query.userKey,
            };
            this.$http
                .post('/userbasic/selectValueByKey', params)
                .then(res => {
                    let fileList = res.data.sourceMaterialValue;
                    let imgList = [];
                    let videoList = [];
                    fileList.map((item, index) => {
                        if (item.indexOf('.jpg') != -1 || item.indexOf('.png') != -1 || item.indexOf('.jpeg') != -1 || item.indexOf('.bmp') != -1 || item.indexOf('.gif') != -1) {
                            let obj = {
                                uid: index,
                                name: item,
                                status: 'done',
                                url: process.env.VUE_APP_RESOURCES_HOST + item,
                                type: 'image',
                                response: {
                                    data: {
                                        filePath: item,
                                    },
                                },
                            };
                            imgList.push(obj);
                        } else if (item.indexOf('.mp4') != -1 || item.indexOf('.rmvb') != -1 || item.indexOf('.rm') != -1 || item.indexOf('.flv') != -1) {
                            let obj = {
                                uid: index,
                                name: item,
                                status: 'done',
                                url: process.env.VUE_APP_RESOURCES_HOST + item.split('&')[1],
                                type: 'video',
                                thumbUrl: 'https://resources.xbov.cn/img/hxznggfwpt/videoCodeImg.png',
                                response: {
                                    data: {
                                        coverImgUrl: null,
                                        duration: Number(item.split('&')[0]),
                                        filePath: item.split('&')[1],
                                    },
                                },
                            };
                            videoList.push(obj);
                        }
                    });
                    if (imgList.length == 0) {
                        _this.materialBtnList.map(item => {
                            if (item.type == 1) {
                                item.check = false;
                            }
                        });
                    } else {
                        _this.materialBtnList.map(item => {
                            if (item.type == 1) {
                                item.check = true;
                            }
                        });
                    }
                    if (videoList.length == 0) {
                        _this.materialBtnList.map(item => {
                            if (item.type == 2) {
                                item.check = false;
                            }
                        });
                    } else {
                        _this.materialBtnList.map(item => {
                            if (item.type == 2) {
                                item.check = true;
                            }
                        });
                    }
                    if (imgList.length == 0 && videoList.length == 0) {
                        _this.materialBtnList.map(item => {
                            if (item.type == 1) {
                                item.check = true;
                            }
                            if (item.type == 2) {
                                item.check = false;
                            }
                        });
                    }
                    let totalVideoDuration = 0;
                    videoList.map(item => {
                        totalVideoDuration += Number(item.response.data.duration);
                    });
                    _this.imgFileList = imgList;
                    _this.videoFileList = videoList;
                    _this.totalVideoDuration = totalVideoDuration;
                    _this.getPrice();
                })
                .catch(err => {
                    console.log(err);
                });
        },
        changeTradeId(data) {
            this.tradeId = data;
        },
        getTextPriceTotal() {
            return (Number(this.getTextPriceDefault()) + Number(this.getTextPriceMax()) * Number(this.content.length - 140)).toFixed(2);
        },
        //文本计价
        getTextPriceMax() {
            let price = 0;
            if (this.currentDetectTyType == 1) {
                price = ((Number(this.textUnitPrice.intelligence) / 140) * 2).toFixed(2);
                return price;
            } else if (this.currentDetectTyType == 2) {
                price = ((Number(this.textUnitPrice.artificial) / 140) * 2).toFixed(2);
                return price;
            } else if (this.currentDetectTyType == 3) {
                price = (
                    ((Number((this.textUnitPrice.artificial * (this.textUnitPrice.expert.replace('%', '') / 100)).toFixed(2)) + Number(this.textUnitPrice.artificial)).toFixed(2) / 140) *
                    2
                ).toFixed(2);
                return price;
            }
        },
        getTextPriceDefault() {
            let price = 0;
            if (this.currentDetectTyType == 1) {
                price = Number(this.textUnitPrice.intelligence);
                return price;
            } else if (this.currentDetectTyType == 2) {
                price = Number(this.textUnitPrice.artificial);
                return price;
            } else if (this.currentDetectTyType == 3) {
                price = (Number((this.textUnitPrice.artificial * (this.textUnitPrice.expert.replace('%', '') / 100)).toFixed(2)) + Number(this.textUnitPrice.artificial)).toFixed(2);
                return price;
            }
        },
        getUserSetting(data) {
            let params = {
                userCode: data,
                configKey: 'reviewPrice',
            };
            const _this = this;
            this.$http
                .post('/usersetting/queryList', params)
                .then(res => {
                    if (res.data.dataList.length == 0) {
                        _this.customPriceFlag = false;
                        _this.querySystemsettingPageList();
                        _this.getPrice();
                    } else {
                        let obj = res.data.dataList[0];
                        if (obj.configValue != '{}') {
                            _this.customPriceFlag = true;
                            let priceObj = JSON.parse(obj.configValue);
                            _this.imgUnitPrice.unitPrice = Number(priceObj.picPrice);
                            _this.videoUnitPrice.unitPrice = Number(priceObj.videoPrice);
                            _this.audioUnitPrice.unitPrice = Number(priceObj.micPrice);
                            _this.textUnitPrice = {
                                minFontPrice: Number(priceObj.minFontPrice),
                                maxFontPrice: Number(priceObj.maxFontPrice),
                            };
                            _this.getPrice();
                        } else {
                            _this.customPriceFlag = false;
                            _this.querySystemsettingPageList();
                            _this.getPrice();
                        }
                    }
                })
                .catch(err => {
                    _this.querySystemsettingPageList();
                    console.log('用户设价配置键失败：', err);
                });
        },
        handleChange(value) {
            this.industry = value;
            this.industryOptions.map(item => {
                if (item.industryName == value) {
                    this.textareaValue = item.remark;
                }
            });
        },
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
        industryOptionsList() {
            const _this = this;
            let params = {};
            if (this.ambientHost === 'production') {
                this.$http
                    .post('/monitortrade/industryViolation', params)
                    .then(res => {
                        this.industryOptions = res.data;
                        let commonIndustries = [...this.commonIndustries];
                        res.data.map(item => {
                            if (
                                item.industryName == '房地产' ||
                                item.industryName == '医疗' ||
                                item.industryName == '保健品' ||
                                item.industryName == '药品' ||
                                item.industryName == '招商' ||
                                item.industryName == '金融' ||
                                item.industryName == '教育' ||
                                item.industryName == '酒类'
                            ) {
                                commonIndustries.push(item);
                            }
                        });
                        this.commonIndustries = commonIndustries;
                    })
                    .catch(err => {
                        console.log('err', err);
                    });
            } else {
                this.industryOptions = IndustryInfo;
                let commonIndustries = [...this.commonIndustries];
                IndustryInfo.map(item => {
                    if (
                        item.industryName == '房地产' ||
                        item.industryName == '医疗' ||
                        item.industryName == '保健品' ||
                        item.industryName == '药品' ||
                        item.industryName == '招商' ||
                        item.industryName == '金融' ||
                        item.industryName == '教育' ||
                        item.industryName == '酒类'
                    ) {
                        commonIndustries.push(item);
                    }
                });
                this.commonIndustries = commonIndustries;
            }
        },
        beforeImgUpload(file) {
            return new Promise((resolve, reject) => {
                var testmsg = /^image\/(jpeg|png|jpg|bmp|gif)$/.test(file.type);
                const isLt20M = file.size / 1024 / 1024 <= 4; //图片大小不超过4MB
                if (!testmsg) {
                    this.$message.error('仅支持图片!');
                    return reject(false);
                }
                if (!isLt20M) {
                    this.$message.error('上传图片大小不能超过4M!');
                    return reject(false);
                }
                return resolve(true);
            });
        },
        beforeVideoUpload(file) {
            return new Promise((resolve, reject) => {
                var testmsg = /^video\/(rmvb|mp4|x-flv|x-matroska|rm|quicktime)$/.test(file.type);
                const isLt20M = file.size / 1024 / 1024 <= 1024; //视频大小不超过20MB
                if (!testmsg) {
                    this.$message.error('仅支持视频!');
                    return reject(false);
                }
                if (!isLt20M) {
                    this.$message.error('上传视频大小不能超过1G(1024M)!');
                    return reject(false);
                }
                return resolve(true);
            });
        },
        beforeAudioUpload(file) {
            return new Promise((resolve, reject) => {
                var testmsg = /^audio\/(mp3|mpeg|wav)$/.test(file.type);
                const isLt20M = file.size / 1024 / 1024 <= 20; //图片大小不超过2MB
                if (!testmsg) {
                    this.$message.error('仅支持音频!');
                    return reject(false);
                }
                if (!isLt20M) {
                    this.$message.error('上传音频大小不能超过20M!');
                    return reject(false);
                }
                return resolve(true);
            });
        },
        beforefileUpload(file) {
            return new Promise((resolve, reject) => {
                let fileType = null;
                // let testmsg = /^(jpeg|png|jpg|bmp|gif)|(doc|docx|pdf)|(rmvb|mp4|x-flv|x-matroska|rm|quicktime)|(zip|rar|gz|.apk)$/.test(file.type);
                let arr = file.name.split('.');
                arr.map(item => {
                    if (
                        item == 'doc' ||
                        item == 'docx' ||
                        item == 'jpeg' ||
                        item == 'png' ||
                        item == 'jpg' ||
                        item == 'gif' ||
                        item == 'pdf' ||
                        item == 'mp4' ||
                        item == 'zip' ||
                        item == 'rar' ||
                        item == 'rmvb' ||
                        item == 'rm' ||
                        item == 'x-flv' ||
                        item == 'bmp' ||
                        item == 'x-matroska' ||
                        item == 'quicktime' ||
                        item == 'jfif' ||
                        item == 'gz'
                    ) {
                        fileType = item;
                    }
                });
                const isLt20M = file.size / 1024 / 1024 <= 20; //图片大小不超过2MB
                if (!fileType) {
                    this.$message.error('文件格式不正确!');
                    return reject(false);
                }
                if (!isLt20M) {
                    this.$message.error('上传文件大小不能超过20M!');
                    return reject(false);
                }
                return resolve(true);
            });
        },
        closePayModal() {
            this.payModalVisible = false;
            this.$router.push({ name: 'CheckOrder' });
        },
        querySystemsettingPageList() {
            const _this = this;
            let params = {};
            this.$http
                .post('/systemsetting/queryList', params)
                .then(res => {
                    _this.payLoading = false;
                    let dataList = res.data.dataList;
                    dataList.map(item => {
                        if (item.configType == 2) {
                            if (item.configKey == 'imgprice') {
                                this.imgUnitPrice = JSON.parse(item.configValue);
                            } else if (item.configKey == 'vedio') {
                                this.videoUnitPrice = JSON.parse(item.configValue);
                            } else if (item.configKey == 'audio') {
                                this.audioUnitPrice = JSON.parse(item.configValue);
                            } else if (item.configKey == 'text') {
                                this.textUnitPrice = JSON.parse(item.configValue);
                            }
                        }
                    });
                })
                .catch(err => {
                    console.log('查询全局系统配置键失败：', err);
                });
        },
        //获取优惠券ID
        getUserCouponId() {
            const _this = this;
            let params = {
                userCode: this.userInfo.userCode,
            };
            _this.$http
                .post('/usercoupon/queryPageList', params)
                .then(res => {
                    _this.loading = false;
                    if (res.data.dataList != '' && res.data.dataList[0].couponType == 0) {
                        _this.userCouponId = res.data.dataList[0].flowId;
                        _this.couponUseType = res.data.dataList[0].couponType;
                        _this.discountForm.videoCount = res.data.dataList[0].specificationsVo.videoSeconds;
                        _this.discountForm.audioCount = res.data.dataList[0].specificationsVo.audioSeconds;
                        _this.discountForm.pictureCount = res.data.dataList[0].specificationsVo.numberOfPictures;
                        _this.discountForm.textCount = res.data.dataList[0].specificationsVo.writtenWords;
                    } else {
                        _this.discount = false;
                    }
                })
                .catch(err => {
                    _this.loading = false;
                });
        },
        getPrice() {
            let list = [];
            let uploading = false;
            for (let i = 0; i < this.materialBtnList.length; i++) {
                if (this.materialBtnList[i].type == 4 && this.materialBtnList[i].check) {
                    if (this.content && this.content != '') {
                        let obj = {
                            content: this.content,
                            contentType: 4,
                            subscript: '4',
                            type: this.currentDetectTyType,
                        };
                        if (this.reviewUserCodeForm.reviewUserCode && this.reviewUserCodeForm.reviewUserCode != '') {
                            obj.expertCode = this.reviewUserCodeForm.reviewUserCode;
                        }
                        //添加优惠券
                        if (this.couponState == 1) {
                            obj.couponState = this.couponState;
                            obj.couponType = this.couponType;
                            if (this.couponType == 4) {
                                obj.userCouponId = this.userCouponId;
                            } else {
                                obj.couponState = 0;
                                obj.couponType = '';
                                obj.userCouponId = '';
                            }
                        } else {
                            obj.couponState = 0;
                            obj.couponType = '';
                            obj.userCouponId = '';
                        }
                        list.push(obj);
                    }
                } else if (this.materialBtnList[i].type == 1 && this.materialBtnList[i].check) {
                    this.imgFileList.map((item, index) => {
                        if (item.status == 'done') {
                            let obj = {
                                contentType: 1,
                                type: this.currentDetectTyType,
                                subscript: '1',
                            };
                            if (this.reviewUserCodeForm.reviewUserCode && this.reviewUserCodeForm.reviewUserCode != '') {
                                obj.expertCode = this.reviewUserCodeForm.reviewUserCode;
                            }
                            //添加优惠券
                            if (this.couponState == 1) {
                                obj.couponState = this.couponState;
                                obj.couponType = this.couponType;
                                if (this.couponType == 1) {
                                    obj.userCouponId = this.userCouponId;
                                } else {
                                    obj.couponState = 0;
                                    obj.couponType = '';
                                    obj.userCouponId = '';
                                }
                            } else {
                                obj.couponState = 0;
                                obj.couponType = '';
                                obj.userCouponId = '';
                            }
                            list.push(obj);
                        } else if (item.status == 'uploading') {
                            uploading = true;
                        }
                    });
                } else if (this.materialBtnList[i].type == 2 && this.materialBtnList[i].check) {
                    this.videoFileList.map((item, index) => {
                        if (item.status == 'done') {
                            let obj = {
                                contentType: 2,
                                type: this.currentDetectTyType,
                                subscript: '2',
                                duration: Number(item.response.data.duration),
                            };
                            if (this.reviewUserCodeForm.reviewUserCode && this.reviewUserCodeForm.reviewUserCode != '') {
                                obj.expertCode = this.reviewUserCodeForm.reviewUserCode;
                            }
                            //添加优惠券
                            if (this.couponState == 1) {
                                obj.couponState = this.couponState;
                                obj.couponType = this.couponType;
                                if (this.couponType == 2) {
                                    obj.userCouponId = this.userCouponId;
                                } else {
                                    obj.couponState = 0;
                                    obj.couponType = '';
                                    obj.userCouponId = '';
                                }
                            } else {
                                obj.couponState = 0;
                                obj.couponType = '';
                                obj.userCouponId = '';
                            }
                            list.push(obj);
                        } else if (item.status == 'uploading') {
                            uploading = true;
                        }
                    });
                } else if (this.materialBtnList[i].type == 3 && this.materialBtnList[i].check) {
                    this.audioFileList.map((item, index) => {
                        if (item.status == 'done') {
                            let obj = {
                                contentType: 3,
                                type: this.currentDetectTyType,
                                subscript: '3',
                                duration: Number(item.response.data.duration),
                            };
                            if (this.reviewUserCodeForm.reviewUserCode && this.reviewUserCodeForm.reviewUserCode != '') {
                                obj.expertCode = this.reviewUserCodeForm.reviewUserCode;
                            }
                            //添加优惠券
                            if (this.couponState == 1) {
                                obj.couponState = this.couponState;
                                obj.couponType = this.couponType;
                                3;
                                if (this.couponType == 3) {
                                    obj.userCouponId = this.userCouponId;
                                } else {
                                    obj.couponState = 0;
                                    obj.couponType = '';
                                    obj.userCouponId = '';
                                }
                            } else {
                                obj.couponState = 0;
                                obj.couponType = '';
                                obj.userCouponId = '';
                            }
                            list.push(obj);
                        } else if (item.status == 'uploading') {
                            uploading = true;
                        }
                    });
                }
            }
            if (uploading) {
                return;
            }
            if (list.length == 0) {
                this.totalDiscountAmount = 0;
                this.totalPrice = 0;
                this.totalPayAmount = 0;
                this.discountprice = 0;
                return;
            }
            const _this = this;
            if (list.length != 0) {
                this.$http
                    .post('/monitortrade/priceStatistics', list)
                    .then(res => {
                        _this.totalDiscountAmount = res.data[0].totalDiscountAmount;
                        _this.totalPrice = res.data[0].totalPrice;
                        _this.totalPayAmount = res.data[0].totalPayAmount;
                        _this.discountprice = res.data[0].couponAmount;
                        _this.dataList = res.data;
                    })
                    .catch(err => {
                        console.log('查询价格失败', err);
                    });
            }
        },
        submit() {
            this.setAmount();
            let monitorOrderCreateFormList = [];
            for (let i = 0; i < this.materialBtnList.length; i++) {
                if (this.materialBtnList[i].type == 4 && this.materialBtnList[i].check) {
                    if (!this.content || this.content == '') {
                        this.$message.warning('请输入需要检测的文本');
                        return;
                    } else {
                        let obj = {
                            content: this.content,
                            contentType: 4,
                            contentUnit: this.content.length,
                            priceAmount: this.realTimePrice.textPrice.priceAmount,
                            payAmount: this.realTimePrice.textPrice.payAmount,
                            discountAmount: this.realTimePrice.textPrice.discountAmount,
                            minFontPrice: this.realTimePrice.textPrice.minFontPrice,
                            maxFontPrice: this.realTimePrice.textPrice.maxFontPrice,
                        };
                        monitorOrderCreateFormList.push(obj);
                    }
                } else if (this.materialBtnList[i].type == 1 && this.materialBtnList[i].check) {
                    if (this.imgFileList.length == 0) {
                        this.$message.warning('请上传需要检测的图片');
                        return;
                    } else {
                        let uploadFaild = false;
                        this.imgFileList.map(item => {
                            if (item.status == 'uploading') {
                                uploadFaild = 1; //正在上传
                            } else if (item.status == 'error') {
                                uploadFaild = 2; //上传失败
                            }
                        });
                        if (uploadFaild == 1) {
                            this.$message.warning('图片正在上传中，请等待图片上传完毕！');
                            return;
                        }
                        if (uploadFaild == 2) {
                            this.$message.warning('图片上传失败，请重新上传！');
                            return;
                        }
                        this.imgFileList.map(item => {
                            let obj = {
                                content: item.response.data.filePath,
                                contentType: 1,
                                contentUnit: 1,
                                priceAmount: this.realTimePrice.imgPrice.priceAmount,
                                payAmount: item.payAmount,
                                aptitudesFile: item.qualificationList ? item.qualificationList[0].response.data.filePath : null,
                                discountAmount: this.realTimePrice.imgPrice.discountAmount,
                                unitPrice: this.realTimePrice.imgPrice.unitPrice,
                                coverImgUrl: item.response.data.coverImgUrl
                                    ? process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl
                                    : 'https://resources.xbov.cn/img/hxznggfwpt/videoCodeImg.png',
                            };
                            monitorOrderCreateFormList.push(obj);
                        });
                    }
                } else if (this.materialBtnList[i].type == 2 && this.materialBtnList[i].check) {
                    if (this.videoFileList.length == 0) {
                        this.$message.warning('请上传需要检测的视频');
                        return;
                    } else {
                        let uploadFaild = false;
                        this.videoFileList.map(item => {
                            if (item.status == 'uploading') {
                                uploadFaild = 1; //正在上传
                            } else if (item.status == 'error') {
                                uploadFaild = 2; //上传失败
                            }
                        });
                        if (uploadFaild == 1) {
                            this.$message.warning('视频正在上传中，请等待视频上传完毕！');
                            return;
                        }
                        if (uploadFaild == 2) {
                            this.$message.warning('视频上传失败，请重新上传！');
                            return;
                        }
                        this.videoFileList.map(item => {
                            let obj = {
                                content: item.response.data.filePath,
                                contentType: 2,
                                contentUnit: Number(item.response.data.duration),
                                priceAmount: this.realTimePrice.videoPrice.priceAmount,
                                payAmount: item.payAmount,
                                aptitudesFile: item.qualificationList ? item.qualificationList[0].response.data.filePath : null,
                                discountAmount: this.realTimePrice.videoPrice.discountAmount,
                                unitPrice: this.realTimePrice.videoPrice.unitPrice,
                                coverImgUrl: item.response.data.coverImgUrl
                                    ? process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl
                                    : 'https://resources.xbov.cn/img/hxznggfwpt/videoCodeImg.png',
                            };
                            monitorOrderCreateFormList.push(obj);
                        });
                    }
                } else if (this.materialBtnList[i].type == 3 && this.materialBtnList[i].check) {
                    if (this.audioFileList.length == 0) {
                        this.$message.warning('请上传需要检测的音频');
                        return;
                    } else {
                        let uploadFaild = false;
                        this.audioFileList.map(item => {
                            if (item.status == 'uploading') {
                                uploadFaild = 1; //正在上传
                            } else if (item.status == 'error') {
                                uploadFaild = 2; //上传失败
                            }
                        });
                        if (uploadFaild == 1) {
                            this.$message.warning('音频正在上传中，请等待音频上传完毕！');
                            return;
                        }
                        if (uploadFaild == 2) {
                            this.$message.warning('音频上传失败，请重新上传！');
                            return;
                        }
                        this.audioFileList.map(item => {
                            let obj = {
                                content: item.response.data.filePath,
                                contentType: 3,
                                contentUnit: Number(item.response.data.duration),
                                priceAmount: this.realTimePrice.audioPrice.priceAmount,
                                payAmount: item.payAmount,
                                aptitudesFile: item.qualificationList ? item.qualificationList[0].response.data.filePath : null,
                                discountAmount: this.realTimePrice.audioPrice.discountAmount,
                                unitPrice: this.realTimePrice.audioPrice.unitPrice,
                                coverImgUrl: 'https://resources.xbov.cn/img/hxznggfwpt/audioCodeImg.jpg',
                            };
                            monitorOrderCreateFormList.push(obj);
                        });
                    }
                }
            }
            if (!this.industry) {
                this.$message.warning('请选择行业！');
                return;
            }
            if (this.currentDetectTyType == 3) {
                if (!this.reviewUserCodeForm.reviewUserCode || this.reviewUserCodeForm.reviewUserCode == '') {
                    this.$message.warning('请输入专家编号');
                    return;
                }
            }
            if (this.reviewUserCodeFaild) {
                this.$message.warning('请重新输入正确的专家编号！');
                return;
            }
            monitorOrderCreateFormList.map(item => {
                item.industry = this.industry;
            });
            let params = {
                monitorOrderCreateFormList: monitorOrderCreateFormList,
                discountAmount: this.totalDiscountAmount,
                orderSource: 1,
                payAmount: this.totalPayAmount,
                totalAmount: this.totalPrice,
                type: this.currentDetectTyType,
            };
            //使用优惠券时传值
            if (this.couponState == 1) {
                params.couponState = 1;
                params.couponAmount = this.discountprice;
                params.userCoupon = this.userCouponId;
            }
            if (this.currentDetectTyType == 3) {
                params.reviewUserCode = this.reviewUserCodeForm.reviewUserCode;
            }
            const _this = this;
            _this.payLoading = true;
            if (_this.userInfo.userType == 2) {
                this.$http
                    .post('/monitortrade/add', params)
                    .then(res => {
                        _this.tradeId = res.data;
                        _this.payData = params;
                        if (params.payAmount == 0 || (_this.userInfo.userType == 2 && _this.freePeriod && this.currentDetectTyType == 1)) {
                            _this.$router.push({ name: 'CheckOrder' });
                        } else {
                            _this.payModalVisible = true;
                        }
                    })
                    .catch(err => {
                        console.log('err', err);
                        _this.payLoading = false;
                    });
            } else if (_this.userInfo.userType == 1) {
                // if (_this.userInfo.isVip == 0 && (_this.currentDetectTyType == 3 || _this.$moment().format('x') > _this.$moment(_this.userInfo.freeReviewTime).format('x'))) {
                if (_this.userInfo.isVip == 0) {
                    this.$http
                        .post('/monitortrade/add', params)
                        .then(res => {
                            _this.tradeId = res.data;
                            _this.payData = params;
                            if (params.payAmount == 0 || (_this.userInfo.userType == 2 && _this.freePeriod && this.currentDetectTyType == 1)) {
                                _this.$router.push({ name: 'CheckOrder' });
                            } else {
                                _this.payModalVisible = true;
                            }
                        })
                        .catch(err => {
                            console.log('err', err);
                            _this.payLoading = false;
                        });
                } else if (this.userInfo.isVip == 1 || this.userInfo.isVip == 2) {
                    this.$http
                        .post('/monitortrade/vipAddOrder', params)
                        .then(res => {
                            _this.tradeId = res.data;
                            _this.payData = params;
                            if (params.payAmount == 0) {
                                _this.$router.push({ name: 'CheckOrder' });
                            } else {
                                _this.payModalVisible = true;
                            }
                        })
                        .catch(err => {
                            console.log('err', err);
                        })
                        .finally(() => {
                            _this.payLoading = false;
                        });
                }
            }
        },
        closeMediaModal() {
            this.mediaVisible = false;
            this.mediaSrc = null;
            this.mediaType = null;
        },
        previewVideo() {
            this.mediaSrc = 'https://resources.xbov.cn/help/%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0pc%E7%94%A8%E6%88%B7%E7%AB%AF%E4%BD%BF%E7%94%A8%E6%95%99%E7%A8%8B.mp4';
            this.mediaVisible = true;
            this.mediaType = 2;
        },
        async handlePreview(file) {
            if (file.type.indexOf('image') != -1) {
                this.$refs.viewer.show([
                    {
                        thumbnail: process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath,
                        source: process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath,
                    },
                ]);
            } else if (file.type.indexOf('video') != -1) {
                this.mediaSrc = process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath;
                this.mediaVisible = true;
                this.mediaType = 2;
            } else if (file.type.indexOf('audio') != -1) {
                this.mediaSrc = process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath;
                this.mediaVisible = true;
                this.mediaType = 3;
            } else {
                this.mediaSrc = process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath;
                this.mediaVisible = true;
                this.mediaType = 3;
            }
        },
        async handleImgChange({ file, fileList }) {
            if (file.status == 'done' && file.response.rescode == 200) {
                fileList.map(item => {
                    if (item.status == 'done') {
                        if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != '') {
                            item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
                        }
                        this.FileInfo = item.response.data;
                    }
                });
            } else if (file.status == 'done' && file.response.rescode == 201) {
                this.$message.warning(file.response.msg);
                fileList = fileList.filter(item => {
                    return item.uid != file.uid;
                });
            }
            this.imgFileList = fileList;
            this.getPrice();
        },
        async handleVideoChange({ file, fileList }) {
            let totalVideoDuration = 0;
            if (file.status == 'done') {
                fileList.map(item => {
                    if (item.status == 'done') {
                        if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != '') {
                            item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
                        } else {
                            item.thumbUrl = 'https://resources.xbov.cn/img/hxznggfwpt/videoCodeImg.png';
                        }
                        totalVideoDuration += Number(item.response.data.duration);
                    }
                });
            } else if (file.status == 'removed') {
                fileList.map(item => {
                    if (item.status == 'done') {
                        totalVideoDuration += Number(item.response.data.duration);
                    }
                });
            } else if (file.status == 'done' && file.response.rescode == 201) {
                this.$message.warning(file.response.msg);
                fileList = fileList.filter(item => {
                    return item.uid != file.uid;
                });
            }
            this.videoFileList = fileList;
            this.totalVideoDuration = totalVideoDuration;
            this.getPrice();
        },
        async handleAudioChange({ file, fileList }) {
            let totalAudioDuration = 0;
            if (file.status == 'done') {
                fileList.map(item => {
                    if (item.status == 'done') {
                        item.thumbUrl = 'https://resources.xbov.cn/img/hxznggfwpt/audioCodeImg.jpg';
                    }
                    totalAudioDuration += Number(item.response.data.duration);
                });
            } else if (file.status == 'removed') {
                fileList.map(item => {
                    if (item.status == 'done') {
                        totalAudioDuration += Number(item.response.data.duration);
                    }
                });
            } else if (file.status == 'done' && file.response.rescode == 201) {
                this.$message.warning(file.response.msg);
                fileList = fileList.filter(item => {
                    return item.uid != file.uid;
                });
            }
            this.audioFileList = fileList;
            this.totalAudioDuration = totalAudioDuration;
            this.getPrice();
        },
        async handlefileChange({ file, fileList }, DataId) {
            let totalDuration = 0;
            if (file.status == 'done' && file.response.rescode == 200) {
                fileList.map(item => {
                    if (item.status == 'done') {
                        if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != '') {
                            item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
                            item.filePath = item.response.data.filePath;
                        } else if (file.type.indexOf('video') != -1) {
                            item.thumbUrl = 'https://resources.xbov.cn/img/hxznggfwpt/videoCodeImg.png';
                            totalDuration += Number(item.response.data.duration);
                        } else {
                            totalDuration = 0;
                        }
                    }
                });
            } else if (file.status == 'done' && file.response.rescode == 201) {
                this.$message.warning(file.response.msg);
                fileList = fileList.filter(item => {
                    return item.uid != file.uid;
                });
            } else if (file.status == 'removed') {
                if (this.imgFileList) {
                    let imgFileList = JSON.parse(JSON.stringify(this.imgFileList));
                    imgFileList.map(item => {
                        if (item.response.data.id === DataId) {
                            item.qualificationList = [];
                        }
                    });
                }
                if (this.videoFileList) {
                    let videoFileList = JSON.parse(JSON.stringify(this.videoFileList));
                    videoFileList.map(item => {
                        if (item.response.data.id === DataId) {
                            item.qualificationList = [];
                        }
                    });
                }
                if (this.audioFileList) {
                    let audioFileList = JSON.parse(JSON.stringify(this.audioFileList));
                    audioFileList.map(item => {
                        if (item.response.data.id === DataId) {
                            item.qualificationList = [];
                        }
                    });
                }
            }
            if (this.imgFileList) {
                let imgFileList = JSON.parse(JSON.stringify(this.imgFileList));
                imgFileList.map(item => {
                    if (item.response.data.id === DataId) {
                        item.qualificationList = fileList;
                    }
                });
                this.imgFileList = imgFileList;
            }
            if (this.videoFileList) {
                let videoFileList = JSON.parse(JSON.stringify(this.videoFileList));
                videoFileList.map(item => {
                    if (item.response.data.id === DataId) {
                        item.qualificationList = fileList;
                    }
                });
                this.videoFileList = videoFileList;
            }
            if (this.audioFileList) {
                let audioFileList = JSON.parse(JSON.stringify(this.audioFileList));
                audioFileList.map(item => {
                    if (item.response.data.id === DataId) {
                        item.qualificationList = fileList;
                        // item.aptitudesFile =
                    }
                });
                this.audioFileList = audioFileList;
            }
            // console.log(this.imgFileList);
            // console.log(this.imgFileList[0].qualificationList[0].response.data.filePath);
            // console.log(fileList);
        },
        selectedMaterialType(type) {
            let checkNum = 0;
            for (let i = 0; i < this.materialBtnList.length; i++) {
                if (this.materialBtnList[i].type == type) {
                    this.materialBtnList[i].check = !this.materialBtnList[i].check;
                    if (this.materialBtnList[i].check) {
                        if (type == 1) {
                            this.imgFileList = [];
                        } else if (type == 2) {
                            this.videoFileList = [];
                        } else if (type == 3) {
                            this.audioFileList = [];
                        } else {
                            this.content = '';
                        }
                    }
                }
                if (this.materialBtnList[i].check) {
                    checkNum += 1;
                }
            }
            if (checkNum == 0) {
                this.$message.warning('至少选择一种素材内容！');
                for (let i = 0; i < this.materialBtnList.length; i++) {
                    if (this.materialBtnList[i].type == type) {
                        this.materialBtnList[i].check = true;
                    }
                }
            }
            this.getPrice();
        },
        selectedDetectyType(type) {
            this.currentDetectTyType = type;
        },
        onChange(e) {
            let { checked } = e.target;
            if (checked) {
                this.couponState = 1;
            } else {
                this.couponState = 0;
            }
            this.getPrice();
        },
    },
};
</script>

<style lang="less" scoped>
.pageContainer {
    .CheckAdvertisingBox {
        height: 100%;

        .ant-spin-nested-loading {
            height: 100%;

            /deep/ .ant-spin-container {
                height: 100%;
            }
        }

        .subTitle {
            font-size: 16px;
            line-height: 22px;
            padding: 16px;
        }

        .prompt {
            color: #f57474;
            font-size: 16px;
        }

        .btnGroup {
            padding: 0 16px 16px;
            display: flex;
            justify-content: space-between;

            .ant-btn {
                width: 120px;
            }
        }

        .ant-col {
            height: 100%;
        }

        &-left-box {
            height: 100%;
            background: #fff;

            .industrySelectedBox {
                padding: 16px;
                font-size: 16px;

                .ant-select {
                    width: 140px;
                }

                .grayText {
                    margin-top: 8px;
                    font-size: 14px;
                    color: #bfbfbf;
                }
            }

            .discountBox {
                padding: 0 16px 16px;
                font-size: 16px;

                .ant-select {
                    width: 140px;
                }

                .grayText {
                    margin-top: 8px;
                    font-size: 14px;
                    color: #bfbfbf;
                }
            }

            .commonIndustriesBox {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                padding: 0 16px;

                .ant-btn {
                    width: 23%;
                    margin-bottom: 8px;
                }
            }

            .textareaBox {
                padding: 0 16px;
                font-size: 16px;

                .textareaValue {
                    border: 0;
                    // height: 180px;
                    outline: none;
                }

                // .textareaValue:focus {
                //     border: 0;
                //     outline: none;
                // }
            }

            .materialSelectedBox {
                .prompt {
                    padding: 0 16px 16px;

                    div {
                        margin-bottom: 10px;
                    }
                }

                .materialBtnGroup {
                    flex-wrap: wrap;
                    justify-content: space-around;

                    .ant-btn {
                        width: 140px;
                        margin-bottom: 16px;
                    }
                }
            }

            .detectTypeBox {
                .expertNumber {
                    padding: 0 16px 16px;
                    font-size: 16px;

                    .ant-form {
                        width: 100%;
                    }
                }

                .prompt {
                    padding: 0 16px 16px;
                }
            }

            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                padding: 16px 0;
                color: #178bf1;
            }
        }

        &-right-box {
            height: 100%;
            background: #fff;
            padding: 16px 0;
            overflow-y: auto;

            .submitContainer {
                padding: 20px 16px;

                .submitBox {
                    font-size: 16px;

                    span {
                        color: #ff0014;
                        font-weight: 600;
                    }

                    .submitBtn {
                        margin-left: 16px;
                    }
                }
            }

            .uploadContainer {
                .imgUploadBox {
                }

                .uploadBox {
                    .qualificatBox {
                        overflow: hidden;
                        padding-left: 16px;

                        .qualificatItme {
                            float: left;
                        }
                    }

                    .textareaBox {
                        padding: 0 16px 8px;
                    }

                    .ant-upload-picture-card-wrapper {
                        // padding: 0 16px;
                    }

                    .prompt {
                        padding: 0 16px 8px;
                    }

                    .discountSize {
                        color: #ff0014;
                    }

                    .priceBox {
                        padding: 0 16px;
                        font-size: 16px;

                        .price {
                            color: #ff0014;
                            font-weight: 600;
                        }
                    }

                    .ant-divider {
                        margin: 8px 0;
                    }
                }
            }
        }
    }
}
</style>