<template>
    <div class="pageContainer">
        <a-row class="CheckAdvertisingBox" :gutter="8">
            <a-spin :spinning="payLoading">
                <a-col class="CheckAdvertisingBox-left" :span="6">
                    <div class="CheckAdvertisingBox-left-box">
                        <div class="industrySelectedBox">
                            选择广告投放行业：
                            <a-select placeholder="请选择行业" show-search v-model="industry" @change="handleChange" :filter-option="filterOption">
                                <a-select-option v-for="item in industryOptions" :key="item.flowId" :value="item.industryName">{{ item.industryName }}</a-select-option>
                            </a-select>
                            <div class="prompt">
                                <a-alert message="行业选择会影响检测结果的准确率。" banner />
                            </div>
                        </div>

                        <div class="commonIndustriesBox">
                            <template>
                                <a-button v-for="item in commonIndustries" @click="handleChange(item.industryName)" :type="industry == item.industryName ? 'primary' : ''" :key="item.flowId">
                                    {{ item.industryName }}
                                </a-button>
                            </template>
                        </div>
                        <!-- <div class="textareaBox">
                            <a-textarea class="textareaValue" readOnly placeholder="广告常见违法情形汇总（请选择行业）" v-model="textareaValue" :rows="10" :auto-size="{ minRows: textAreaMinRows, maxRows: textAreaMaxRows }" />
                        </div> -->
                    </div>
                </a-col>
                <a-col class="CheckAdvertisingBox-right" :span="18">
                    <div class="CheckAdvertisingBox-right-box">
                        <div class="uploadContainer">
                            <div class="imgUploadBox uploadBox">
                                <div class="prompt">
                                    <a-alert description="合同请上传word文档(doc、docx)文件，单个文件大小不可超过10MB，个数不超过4个" type="success" />
                                </div>
                                <a-upload :action="resourcesHost" list-type="picture-card" :before-upload="beforeFileUpload" :file-list="fileList" @change="handleFileChange">
                                    <div v-if="fileList.length < 4">
                                        <a-icon type="plus" />
                                        <div class="ant-upload-text">上传合同</div>
                                    </div>
                                </a-upload>
                                <a-divider></a-divider>
                                <div class="priceBox flex justify-between align-center">
                                    <div>
                                        价格明细：
                                        <span class="price">￥{{ fileUnitPrice }}元/个</span>
                                        X {{ fileList.length }} 个， 合计：{{ (fileUnitPrice * fileList.length).toFixed(2) }}
                                        元
                                    </div>
                                </div>
                                <a-divider></a-divider>
                            </div>
                        </div>
                        <div class="submitContainer flex justify-end align-center">
                            <div class="submitBox">
                                <font>
                                    付款金额：
                                    {{ totalPrice > 0 ? totalPrice : 0 }}
                                    元， 优惠减免：
                                    {{ totalDiscountAmount > 0 ? totalDiscountAmount : 0 }}
                                    元， 实付金额：
                                    <span style="font-size: 24px">{{ totalPayAmount }}</span>
                                    元
                                </font>
                                <a-button type="primary" class="submitBtn" @click="submit">提交检测</a-button>
                            </div>
                        </div>
                    </div>
                </a-col>
            </a-spin>
        </a-row>
        <PayModal :payData="payData" :payModalVisible="payModalVisible" :tradeId="tradeId" :closePayModal="closePayModal" />
    </div>
</template>
<script>
import PayModal from './components/PayModal.vue';
import IndustryInfo from '../../utils/common/industryViolation.json';

export default {
    components: {
        PayModal,
    },
    data() {
        return {
            ambientHost: process.env.NODE_ENV,
            payLoading: true,
            industryOptions: [],
            textareaValue: '',
            fileList: [],
            resourcesHost: '',
            industry: undefined,
            payData: {},
            payModalVisible: false,
            totalDiscountAmount: 0, // 折扣金额
            totalPayAmount: 0, // 实付金额
            totalPrice: 0, // 总金额
            tradeId: null, // 主订单号
            fileUnitPrice: {},
            userInfo: {},
            commonIndustries: [],
            textAreaMinRows: 9,
            textAreaMaxRows: 9,
        };
    },
    created() {
        this.resourcesHost = process.env.VUE_APP_RESOURCES_API_HOST;
        this.userInfo = this.$store.getters.userInfo;
        this.querySystemsettingPageList();
        this.industryOptionsList();
    },
    methods: {
        closePayModal() {
            this.payModalVisible = false;
            this.$router.push({ name: 'ContractOrder' });
        },
        industryOptionsList() {
            const _this = this;
            let params = {};
            if (this.ambientHost === 'production') {
                this.$http
                    .post('/monitortrade/industryViolation', params)
                    .then(res => {
                        this.industryOptions = res.data;
                        let commonIndustries = [...this.commonIndustries];
                        res.data.map(item => {
                            if (
                                item.industryName == '房地产' ||
                                item.industryName == '医疗' ||
                                item.industryName == '保健品' ||
                                item.industryName == '药品' ||
                                item.industryName == '招商' ||
                                item.industryName == '金融' ||
                                item.industryName == '教育' ||
                                item.industryName == '酒类'
                            ) {
                                commonIndustries.push(item);
                            }
                        });
                        this.commonIndustries = commonIndustries;
                    })
                    .catch(err => {
                        console.log('err', err);
                    });
            } else {
                this.industryOptions = IndustryInfo;
                let commonIndustries = [...this.commonIndustries];
                IndustryInfo.map(item => {
                    if (
                        item.industryName == '房地产' ||
                        item.industryName == '医疗' ||
                        item.industryName == '保健品' ||
                        item.industryName == '药品' ||
                        item.industryName == '招商' ||
                        item.industryName == '金融' ||
                        item.industryName == '教育' ||
                        item.industryName == '酒类'
                    ) {
                        commonIndustries.push(item);
                    }
                });
                this.commonIndustries = commonIndustries;
            }
        },
        querySystemsettingPageList() {
            const _this = this;
            let params = {};
            this.$http
                .post('/systemsetting/queryList', params)
                .then(res => {
                    _this.payLoading = false;
                    let dataList = res.data.dataList;
                    dataList.map(item => {
                        if (item.configKey == 'contractPrice') {
                            this.fileUnitPrice = JSON.parse(item.configValue).unitPrice;
                        }
                    });
                })
                .catch(err => {
                    console.log('查询全局系统配置键失败：', err);
                });
        },
        submit() {
            let contractFileCreateForms = [];
            if (!this.industry) {
                this.$message.warning('请选择行业！');
                return;
            }
            if (this.fileList.length == 0) {
                this.$message.warning('请上传需要检测的合同');
                return;
            } else {
                let uploadFaild = false;
                this.fileList.map(item => {
                    if (item.status == 'uploading') {
                        uploadFaild = 1; //正在上传
                    } else if (item.status == 'error') {
                        uploadFaild = 2; //上传失败
                    }
                });
                if (uploadFaild == 1) {
                    this.$message.warning('合同正在上传中，请等待合同上传完毕！');
                    return;
                }
                if (uploadFaild == 2) {
                    this.$message.warning('合同上传失败，请重新上传！');
                    return;
                }
                this.fileList.map(item => {
                    let obj = {
                        industryName: this.industry,
                        type: item.type == 'application/pdf' ? 2 : 1,
                        url: item.response.data.filePath,
                    };
                    contractFileCreateForms.push(obj);
                });
            }
            let isVip = false;
            if (this.userInfo.isVip && this.$moment().format('x') <= this.userInfo.freeReviewTime) {
                isVip = true;
            }
            let params = {
                amount: this.totalPayAmount * 1000,
                contractFileCreateForms,
                discountAmount: this.totalDiscountAmount * 1000,
                price: this.totalPrice * 1000,
                remark: '',
                sum: contractFileCreateForms.length,
                type: isVip ? 3 : 1,
                userCode: this.userInfo.userCode,
            };
            if (this.userInfo.isVip == 1 && this.totalPayAmount == 0) {
                params.type = 3;
            }
            const _this = this;
            _this.payLoading = true;
            this.$http
                .post('/resource/contracttrade/add', params)
                .then(res => {
                    _this.tradeId = res.data.contractTrade;
                    _this.payData = params;
                    if (params.amount == 0 || (_this.userInfo.userType == 2 && _this.freePeriod && this.currentDetectTyType == 1)) {
                        _this.$router.push({ name: 'ContractOrder' });
                    } else {
                        _this.payModalVisible = true;
                    }
                })
                .catch(err => {
                    console.log('err', err);
                    _this.payLoading = false;
                });
        },
        getPrice() {
            let list = [];
            let uploading = false;
            this.fileList.map((item, index) => {
                if (item.status == 'done') {
                    list.push(item);
                } else if (item.status == 'uploading') {
                    uploading = true;
                }
            });
            if (uploading) {
                return;
            }
            if (list.length == 0) {
                this.totalDiscountAmount = 0;
                this.totalPrice = 0;
                this.totalPayAmount = 0;
                return;
            }
            const _this = this;
            if (list.length != 0) {
                let params = {
                    sum: list.length,
                };
                this.$http
                    .post('/resource/contracttrade/calculatePrice', params)
                    .then(res => {
                        _this.totalDiscountAmount = res.data.discountAmount;
                        _this.totalPrice = res.data.price;
                        _this.totalPayAmount = res.data.actualAmount;
                    })
                    .catch(err => {
                        console.log('查询价格失败', err);
                    });
            }
        },
        async handleFileChange({ file, fileList }) {
            if (file.status == 'done' && file.response.rescode == 200) {
                fileList.map(item => {
                    if (item.status == 'done') {
                        if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != '') {
                            item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
                        }
                    }
                });
            } else if (file.status == 'done' && file.response.rescode == 201) {
                this.$message.warning(file.response.msg);
                fileList = fileList.filter(item => {
                    return item.uid != file.uid;
                });
            }
            this.fileList = fileList;
            this.getPrice();
        },
        beforeFileUpload(file) {
            return new Promise((resolve, reject) => {
                let fileType = null;
                console.log(file);
                let arr = file.name.split('.');
                arr.map(item => {
                    if (item == 'doc' || item == 'docx') {
                        fileType = item;
                    }
                });
                const isLt20M = file.size / 1024 / 1024 <= 10; //图片大小不超过10MB
                if (!fileType) {
                    this.$message.error('仅支持WORD（格式为doc、docx）文件!');
                    return reject(false);
                }
                if (!isLt20M) {
                    this.$message.error('上传文件大小不能超过10M!');
                    return reject(false);
                }
                return resolve(true);
            });
        },
        handleChange(value) {
            this.industry = value;
            this.industryOptions.map(item => {
                if (item.industryName == value) {
                    this.textareaValue = item.remark;
                }
            });
        },
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
    },
};
</script>
<style lang="less" scoped>
.pageContainer {
    .CheckAdvertisingBox {
        height: 100%;

        .ant-spin-nested-loading {
            height: 100%;

            /deep/ .ant-spin-container {
                height: 100%;
            }
        }

        .subTitle {
            font-size: 16px;
            line-height: 22px;
            padding: 16px;
        }

        .prompt {
            color: #f57474;
            font-size: 16px;
        }

        .btnGroup {
            padding: 0 16px 16px;
            display: flex;
            justify-content: space-between;

            .ant-btn {
                width: 120px;
            }
        }

        .ant-col {
            height: 100%;
        }

        &-left-box {
            height: 100%;
            background: #fff;

            .industrySelectedBox {
                padding: 16px;
                font-size: 16px;

                .ant-select {
                    width: 140px;
                }

                .grayText {
                    margin-top: 8px;
                    font-size: 14px;
                    color: #bfbfbf;
                }
            }

            .commonIndustriesBox {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                padding: 0 16px;

                .ant-btn {
                    width: 23%;
                    margin-bottom: 8px;
                }
            }

            .textareaBox {
                padding: 0 16px;
                font-size: 16px;

                .textareaValue {
                    border: 0;
                    // height: 180px;
                    outline: none;
                }

                // .textareaValue:focus {
                //     border: 0;
                //     outline: none;
                // }
            }

            .materialSelectedBox {
                .prompt {
                    padding: 0 16px 16px;

                    div {
                        margin-bottom: 10px;
                    }
                }

                .materialBtnGroup {
                    flex-wrap: wrap;
                    justify-content: space-around;

                    .ant-btn {
                        width: 140px;
                        margin-bottom: 16px;
                    }
                }
            }

            .detectTypeBox {
                .expertNumber {
                    padding: 0 16px 16px;
                    font-size: 16px;

                    .ant-form {
                        width: 100%;
                    }
                }

                .prompt {
                    padding: 0 16px 16px;
                }
            }

            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                padding: 16px 0;
                color: #178bf1;
            }
        }

        &-right-box {
            height: 100%;
            background: #fff;
            padding: 16px 0;
            overflow-y: auto;

            .submitContainer {
                padding: 20px 16px;

                .submitBox {
                    font-size: 16px;

                    span {
                        color: #ff0014;
                        font-weight: 600;
                    }

                    .submitBtn {
                        margin-left: 16px;
                    }
                }
            }

            .uploadContainer {
                .uploadBox {
                    .textareaBox {
                        padding: 0 16px 8px;
                    }

                    .ant-upload-picture-card-wrapper {
                        padding: 0 16px;
                    }

                    .prompt {
                        padding: 0 16px 8px;
                    }

                    .priceBox {
                        padding: 0 16px;
                        font-size: 16px;

                        .price {
                            color: #ff0014;
                            font-weight: 600;
                        }
                    }

                    .ant-divider {
                        margin: 8px 0;
                    }
                }
            }
        }
    }
}
</style>