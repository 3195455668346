<template>
    <a-modal :visible="changePassWordVisible" title="修改密码" @cancel="closeModal" :footer="null" :closable="false" :maskClosable="false">
        <a-alert message="系统检测到您是第一次注册的新用户，为了您的账号安全考虑，请填写以下相关信息修改原始密码。"></a-alert>
        <a-form-model ref="forgetPasswordForm" :model="forgetPasswordForm" :rules="rules" @submit="submit">
            <a-form-model-item prop="phone" :whitespace="true">
                <a-input v-model="forgetPasswordForm.phone" placeholder="请输入您的手机号">
                    <a-icon slot="prefix" type="user" />
                </a-input>
            </a-form-model-item>
            <a-form-model-item prop="loginPass" :whitespace="true">
                <a-input-password v-model="forgetPasswordForm.loginPass" placeholder="请输入您的密码">
                    <a-icon slot="prefix" type="lock" />
                </a-input-password>
            </a-form-model-item>
            <a-form-model-item prop="confirmPass" :whitespace="true">
                <a-input-password v-model="forgetPasswordForm.confirmPass" placeholder="请输入您的确认密码">
                    <a-icon slot="prefix" type="lock" />
                </a-input-password>
            </a-form-model-item>
            <a-form-model-item prop="userPhoneSms" :whitespace="true">
                <a-input-group compact>
                    <a-input class=" userPhoneSmsInput" v-model="forgetPasswordForm.userPhoneSms" placeholder="请输入您的验证码"> </a-input>
                    <a-button type="primary" :disabled="getSmscodeFlag" class="getCode" @click="getSmsCount">{{ smsCount }} {{ getSmscodeFlag ? "秒" : "" }}</a-button>
                </a-input-group>
            </a-form-model-item>
            <a-form-model-item class="submit">
                <a-button type="primary" class="submit" @click="submit" :loading="confirmLoading" block>确定</a-button>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>
<script>
export default {
    props: ["changePassWordVisible", "closeChangePassWordModal", "phone"],
    data() {
        const _this = this;
        let chekckPhone = (rule, value, callback) => {
            if (value && value != "") {
                let reg = new RegExp(/^1[3456789]\d{9}$/);
                if (!reg.test(value)) {
                    callback("请输入正确的手机号码");
                } else {
                    callback();
                }
            } else {
                callback(new Error("请输入手机号码"));
            }
        };
        let chekckLoginPass = (rule, value, callback) => {
            if (value && value != "") {
                let reg = new RegExp(/^.*(?=.{8,20})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/);
                if (!reg.test(value)) {
                    callback("请输入8至20个字符并包含数字、大小写字母组合的密码！");
                } else {
                    callback();
                }
            } else {
                callback(new Error("请输入密码"));
            }
        };
        let chekckConfirmPassword = (rule, value, callback) => {
            if (value && value != "") {
                if (value != _this.forgetPasswordForm.loginPass) {
                    callback("两次密码不一致，请重新输入");
                } else {
                    callback();
                }
            } else {
                callback(new Error("请输入确认密码"));
            }
        };
        return {
            smsCount: "获取验证码",
            getSmscodeFlag: false,
            confirmLoading: false,
            forgetPasswordForm: {
                phone: undefined,
                loginPass: undefined,
                confirmPass: undefined,
                userPhoneSms: undefined,
            },
            rules: {
                phone: [{ required: true, validator: chekckPhone, trigger: "blur" }],
                loginPass: [{ required: true, validator: chekckLoginPass, trigger: "change" }],
                confirmPass: [{ required: true, validator: chekckConfirmPassword, trigger: "change" }],
                userPhoneSms: [{ required: true, message: "请输入手机验证码", trigger: "change" }],
            },
        };
    },
    watch: {
        changePassWordVisible(data) {
            if (data) {
                this.forgetPasswordForm.phone = this.phone;
            }
        },
    },
    methods: {
        getSmsCount() {
            if (!this.forgetPasswordForm.phone || this.forgetPasswordForm.phone == "") {
                this.$message.warning("请输入手机号");
                return;
            }
            const _this = this;
            this.$http.get("/login/smsSendCode?phone=" + this.forgetPasswordForm.phone + "&type=2").then((res) => {
                if (res.rescode == 200) {
                    _this.$message.success(res.msg);
                    _this.getSmscodeFlag = true;
                    _this.smsCount = 60;
                    let timer = setInterval(() => {
                        _this.smsCount -= 1;
                        if (_this.smsCount == 0) {
                            clearInterval(timer);
                            _this.smsCount = "获取验证码";
                            _this.getSmscodeFlag = false;
                        }
                    }, 1000);
                } else {
                    _this.$message.error(res.msg);
                }
            });
        },
        submit() {
            const _this = this;
            this.$refs.forgetPasswordForm.validate((valid) => {
                if (valid) {
                    let params = {
                        phone: _this.forgetPasswordForm.phone,
                        code: _this.forgetPasswordForm.userPhoneSms,
                        passWord: _this.forgetPasswordForm.loginPass,
                    };
                    _this.confirmLoading = true;
                    _this.$http
                        .post("/usertoken/resetPassword", params)
                        .then((res) => {
                            if (res.rescode == 200) {
                                _this.confirmLoading = false;
                                _this.$message.success("密码修改成功");
                                _this.$refs.forgetPasswordForm.resetFields();
                                _this.closeChangePassWordModal();
                                _this.$router.replace({
                                    name: "CheckAdvertising",
                                    query: {
                                        sourceMaterialKey: this.$route.query.sourceMaterialKey,
                                        tokenKey: this.$route.query.tokenKey,
                                        userKey: this.$route.query.userKey,
                                        resetPassword: 2,
                                    },
                                });
                            } else {
                                _this.$message.warning(res.msg);
                                _this.confirmLoading = false;
                            }
                        })
                        .catch((error) => {
                            _this.confirmLoading = false;
                            console.log(error);
                        });
                }
            });
        },
        closeModal() {
            this.closeChangePassWordModal();
        },
    },
};
</script>
<style lang="less" scoped>
.userPhoneSmsInput {
    width: 78%;
}
</style>
